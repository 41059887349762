<template>
  <div>

  </div>
</template>

<script>
// import bus from '@/eventBus';
export default {

  components: { },
  data() {
    return {
      items: [

      ],
    };
  },
  created() {
  },
  methods: {},
};
</script>
